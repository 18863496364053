@font-face {
  font-family: "DMSans";
  src: url("./fonts/DMSans-VariableFont_opsz,wght.ttf"); // IE9 Compat Modes
  src: url("./fonts/DMSans-VariableFont_opsz,wght.ttf")
      format("embedded-opentype"),
    // IE6-IE8
    url("./fonts/DMSans-VariableFont_opsz,wght.ttf") format("woff2"),
    // Super Modern Browsers
    url("./fonts/DMSans-VariableFont_opsz,wght.ttf") format("woff"),
    // Pretty Modern Browsers
    url("./fonts/DMSans-VariableFont_opsz,wght.ttf") format(
        "truetype"
      ); // Safari, Android, iOS
}

// components
@import "./variables.scss";
@import "../sections/HomePage/HomePage.scss";

*,
html {
  font-family: "DMSans", sans-serif;
  color: $black-text;
  background-color: $gray-dark;
}
