$sm-breakpoint: 768px;
$md-breakpoint: 1024px;

.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1300px;
  flex-wrap: wrap-reverse;

  @media only screen and (max-width: $md-breakpoint) {
    margin: 10px;
    flex-direction: column-reverse;
  }
}

.HomePage {
  &-bold {
    font-weight: 600;
  }
  &-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    flex: 1;
    margin-right: 50px;

    color: $gray-dark;

    &-title {
      color: $black-light;
      font-weight: 800;
      font-size: 36px;
      margin-bottom: 24px;
      line-height: 36px;
    }

    a {
      text-decoration: none;
      color: $primary;
      font-weight: 600;
      &:hover {
        color: $primary-hover;
      }
    }

    @media screen and (max-width: $md-breakpoint) {
      margin: 20px;
    }
  }

  &-imageWrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-caption {
      margin-top: 10px;
      color: $black-light;
    }
  }

  &-imageContent {
    border-radius: 20px;
    max-width: 500px;
    display: flex;

    @media screen and (max-width: $md-breakpoint) {
      max-height: 500px;
    }

    @media screen and (max-width: $sm-breakpoint) {
      max-width: 95%;
      max-height: none;
    }
  }
}

.fade-in-drop {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.fade-in-drop-active {
  opacity: 1;
  transform: translateY(0);
}
