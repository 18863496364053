$gray-light: #f8f9fc;
$gray-dark: #e1e6ef;

$black-text: #0a0d14;
$black-dark: #1b1f27;
$black-light: #3f444d;

// Light Mode Tokens
$primary: #8e2fed;
$primary-hover: #7a1dd6;
